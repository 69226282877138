.App {
  background: #F1F1F1;
  min-height: 100vh;
  font-family: Inter!important;
}

.fullApp {
  margin: 0 auto;
  width: 100%;
}

* {
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{
  -moz-appearance: textfield;
}

